import AcneScarsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/acne-scars/AcneScarsTemplate';

export default {
  title: 'Steps/SkinAppearance/AcneScars/AcneScarsTemplate'
};

const createStory = props => () => ({
  components: { AcneScarsTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    width: '380px'
  },
  template: `<div :style='$options.wrapStyles'>
    <acne-scars-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  faceScarsAppearance: 'raised',
  title: 'On my face, the acne scars are'
});
